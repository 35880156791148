import React, { useState } from 'react';
import './navbar.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/logo.gif';

const Menu = () => (
    <>

    </>
)
const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    return (
        <div className="spacenauts__navbar">
            <div className="spacenauts__navbar-links">
                <div className="spacenauts__navbar-links_logo">
                    <h1 className="theh1"> The Spacenauts</h1>
                </div>
                <div className="spacenauts__navbar-links_container">
                    <Menu />
                </div>
            </div>


        </div>
    )
}

export default Navbar
