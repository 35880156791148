import React from 'react';
import './App.css';

import { Footer, Mint, Roadmap, Features, Whatspacenauts, Header } from './containers';
import { CTA, Brand, Navbar } from './components';
const App = () => {
    return (
        <div className="App">
            <div className="gradient__bg">
                <Navbar />
                <Header />
                <Footer />
            </div>

        </div>
    )
}

export default App
