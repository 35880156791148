import React, { useState } from 'react';
import './footer.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faDiscord } from "@fortawesome/free-brands-svg-icons";

import img1 from '../../assets/opensea.png'
const Footer = () => {
    return (

        <div className="spacenauts__footer">

            <div className="spacenauts__footer-links">

                <div className="spacenauts__footer-links_slogan">

                </div>
                <div className="spacenauts__footer-links_container">
                    <a href="https://opensea.io/collection/the-spacenauts" className="opensea social"><img src={img1} /></a>
                    <a href="https://twitter.com/SpacenautsNFT"><FontAwesomeIcon icon={faTwitter} size="4x" className="twitter social" /></a>
                    <a href="https://discord.gg/5VhXRfx9zf"><FontAwesomeIcon icon={faDiscord} size="4x" className="discord social" /></a>
                </div>
            </div>

        </div>
    )
}

export default Footer
