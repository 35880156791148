import React from 'react';
import './header.css';
import Slideshow from './Slide';

const Header = () => {
    return (
        <div class_Name="spacenauts__header section__padding" id="home">

            <div className="spacenauts__header-content">
                <h1 className="gradient__text"> COMING SOON </h1>
                <Slideshow />

                <p className="pa p1">The Spacenauts are ready for action in space and are set to launch soon. Be there when it happens!</p>
                <p className="pa p2">While things are still under construction: Check out our socials for all the information you need!</p>
            </div>
            <hr />
        </div>
    )
}

export default Header
